import { template as template_0e0154ee7be2455788e1fa3d99b5a08d } from "@ember/template-compiler";
const FKText = template_0e0154ee7be2455788e1fa3d99b5a08d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
