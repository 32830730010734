import { template as template_d70306527f9743a8acb7872cb2c23f7b } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import FKCol from "discourse/form-kit/components/fk/col";
const FKRow = template_d70306527f9743a8acb7872cb2c23f7b(`
  <div class="form-kit__row" ...attributes>
    {{yield (hash Col=FKCol)}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKRow;
