import { template as template_a1101ac390e64004916f556576deef82 } from "@ember/template-compiler";
const WelcomeHeader = template_a1101ac390e64004916f556576deef82(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
