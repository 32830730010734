import { template as template_a231161fb60d4bf79505794ff424b1a1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a231161fb60d4bf79505794ff424b1a1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
